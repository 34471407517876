import * as React from 'react';
import Introduction from '../components/Introduction';
import '../css/global.module.css';
import {Seo}  from '../components/Seo';
import NavBar from '../components/NavBar';
import Services from '../components/Services';
import Contact from '../components/Contact';

const Home = () => {
  return(
    <>
      <NavBar />
      <Introduction/>
      <Services/>
      <Contact/>
    </>
  )
}

export default Home;

export const Head = () => (
  <Seo />
)