import React from 'react';
import {header,section,image,subheader,button,alignContent} from '../css/Introduction.module.css';
import { StaticImage } from 'gatsby-plugin-image';

const Introduction = () => {
    return (
        <>
        <section className={section} id="home">
            <div className={alignContent}>
            <h1 className={header}>
              Designers 
            </h1>
            <h1 className={header}>
              Builders and
            </h1>
            <h1 className={header}>
              Promoters
            </h1>
            <p className={subheader}>
                Construction and Consultancy company turning dream projects into reality.
            </p>
            <a href={`https://wa.me/919790434734?text=I would like to know more about your company`}>
            <button className={button}>
                Contact Us
            </button>
            </a>
            </div>
            <div>
                <StaticImage
                alt="construction company"
                src="../images/header_image.avif"
                className={image}
                />
            </div>
        </section>
        </>
    )
}

export default Introduction;