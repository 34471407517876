import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import React from 'react';
import { Container} from 'react-bootstrap';
import { StaticImage } from 'gatsby-plugin-image';
import "../css/service.css";
import ServiceList from './ServiceList';
function ServicesTabs() {
  return (
    <Tabs
    defaultActiveKey="planning"
    id="fill-tab-example"
    className='mb-3 filter'
    fill
    variant="pills"
  >
    <Tab eventKey="planning" title="Planning" className='tab-align'>
      <p className='description'>We do planning for Residential, Institution buildings like colleges and schools and commercial buildings
        like shopping  complex, hotels, marriage halls and industrial structures</p>
      <StaticImage
            alt="planning"
            src="../images/planning.jpeg"
        />
    </Tab>
    <Tab eventKey="structural-consultant" title="Structural Consultant"  className='tab-align'>
      <p className='description'>
      We are providing structural designs and detailing for Architects and Building Contractors for all type of buildings
      </p>
      <StaticImage
            alt="structural"
            src="../images/structural.jpg"
            className='image-style'
        />
    </Tab>
    <Tab eventKey="building-construction" title="Building Construction"  className='tab-align'>
      <p className='description'>
      The buildings are executed by specialised and experienced engineers to ensure quality.
      </p>
      <StaticImage
            alt="structural"
            src="../images/construction.jpg"
            className='image-style'
        />
    </Tab>
    <Tab eventKey="design" title="Interior and Exterior Design"  className='tab-align'>
      <StaticImage
            alt="structural"
            src="../images/home-exterior.jpeg"
            className='image-style'
        />
      <p className='description'>
      Interior designs such as false ceiling, wall panelling, modular kitchen, hand rails and exterior elevation are executed economically and aesthetically.
      </p>
      <div>
      <StaticImage
            alt="structural"
            src="../images/interior.jpg"
            className='image-style'
        />
      </div>
    </Tab>
    <Tab eventKey="repair" title="Repair and Rehabilitation"  className='tab-align'>
    <StaticImage
            alt="structural"
            src="../images/old-house.jpg"
            className='image-style'
    />
      <p className='description'>
      <span style={{padding:'25%'}}>Change from</span>
      <br/><span style={{padding:'30%',fontSize:'40px'}}>👈 to 👉</span><br/>
      You can reach us to enhance the life span of the existing structures for repairing and rehabilitation of structural elements.
      </p>
      <StaticImage
            alt="structural"
            src="../images/new-house.jpeg"
            className='image-style'
    />
    </Tab>
  </Tabs>
  );
}

const Services = () => {
    return(
        <div className='section' id='services'>
            <h1 style={{textAlign:'center'}} className='header'>We offer amazing services in </h1>
            <Container  className='mobile-view'>
                <ServiceList />
            </Container>
            <Container className='desktop-view'>
                <ServicesTabs /> 
            </Container>
        </div>
    )
}

export default Services;