import { Link } from "gatsby";
import React from 'react';
import { Navbar,Nav } from 'react-bootstrap';

const NavBar = () => {
    return (
        <Navbar collapseOnSelect expand="lg">
                <Navbar.Brand href='#home' style={{color:'#0a1bb3',marginLeft:'20px'}}>Sansar Constructions</Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
                    <Nav>
                        <Nav.Link style={{color:'#0a1bb3'}} href="#services">Services</Nav.Link>
                        <Nav.Link style={{color:'#0a1bb3'}} href="#projects">Projects</Nav.Link>
                        <Nav.Link style={{color:'#0a1bb3'}}>
                            <Link to="/about" style={{textDecoration:'none',color:'#0a1bb3'}}>About Us</Link>
                        </Nav.Link>
                        <Nav.Link style={{color:'#0a1bb3'}} href="#contact">Contact</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
        </Navbar>
    )
}

export default NavBar;