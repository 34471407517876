import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { section,mapAlign,header,btn } from "../css/Contact.module.css";

const Contact = () => {
  return (
    <div className={section} id="contact">
      <Container>
        <h1 className={header}>Contact Us</h1>
        <div className={mapAlign}>
        <iframe
          title="sansar constructions in google map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3890.387800971179!2d79.72968211464246!3d12.818198621694336!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52c34a898fcded%3A0x2771eddf26ce647e!2sSansar%20Constructions!5e0!3m2!1sen!2sin!4v1658674210013!5m2!1sen!2sin"
          width="600"
          height="300"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
        </div>
        <Row className="mt-3">
          <Col xs={4} md={{span: 4, offset:2 }}>
            <h5>Contact</h5>
            <a className="my-5" href='tel:+919790434734'>
              <button className={btn}>
                Ring us
              </button>
            </a>
            <p>Ph No: 9790434734</p>
            <p>Email: sansar.durai@gmail.com</p>
            <p>website: sansarconstructions.in</p>
          </Col>
          <Col xs={8} md={4} >
          <h5>Address</h5>
          <p>
            No:03/13, Sansar Constructions,<br/>
            State Bank Colony, Thrivulluvar Nagar,
            Nattapettai,Kanchipuram,Tamil Nadu 631501
          </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Contact;
