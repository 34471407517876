import React from 'react';
import { Container } from 'react-bootstrap';
import { StaticImage } from 'gatsby-plugin-image';
import "../css/service.css";
export default function ServiceList(){
    return(
        <Container className='my-4'>
          <div className='service-list'>
          <h3 className="list-header">
          Planning
          </h3>
          <p className='description'>We do planning for Residential, Institution buildings like colleges and schools and commercial buildings
        like shopping  complex, hotels, marriage halls and industrial structures</p>
        <StaticImage
            alt="planning"
            src="../images/planning.jpeg"
        />
        </div>
        <div className='service-list'>
        <h3 className="list-header">
          Structural Consultant
        </h3>
         <p className='description'>
        We are providing structural designs and detailing for Architects and Building Contractors for all type of buildings
        </p>
        <StaticImage
            alt="structural"
            src="../images/structural.jpg"
            className='image-style'
        />
        </div>
        <div className='service-list'>
        <h3 className="list-header">
        Building Construction
        </h3>
         <p className='description'>
      The buildings are executed by specialised and experienced engineers to ensure quality.
      </p>
      <StaticImage
            alt="structural"
            src="../images/construction.jpg"
            className='image-style'
        />
        </div>
      <div className='service-list'>
      <h3 className="list-header">Interior and Exterior Design</h3>
      <p className='description'>
      Interior designs such as false ceiling, wall panelling, modular kitchen, hand rails and exterior elevation are executed economically and aesthetically.
      </p>
      <StaticImage
            alt="interior"
            src="../images/interior.jpg"
            className='image-style'
        />
      </div>
      <div className='service-list'>
      <h3 className="list-header">Repair and Rehabilitation</h3>
      <p className='description'>
      You can reach us to enhance the life span of the existing structures for repairing and rehabilitation of structural elements.
      </p>
      <StaticImage
            alt="structural"
            src="../images/new-house.jpeg"
            className='image-style'
      />
      </div>
    </Container>
    )
}